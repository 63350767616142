import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style

import Footer from "../archive/Footer"

// proposal 3
import BlogList from "./BlogList"

import DEMO from "../assets/FormalMethods/DEMO.jpg"

// dash &#x23E4;



export default function DreamMachine() {
    return (
        <>
            <div className="blog-page">
                <BlogList />

                <div className="upvote-and-title">
                    {/* <ItemTest itemId={3}/> */}
                    <h2>The Sunshine</h2>
                    <date><i>- x minute read</i></date>
                </div>     

                
                <div className="blog-page-quote">
                    <p>"Vive amour qui r&#234;ve, embrasse, et fuit"</p>
                    <p>("Long live love that dreams, embraces, and flees")</p>
                    <p><i>- L'Ensoleillad (<a href="https://en.wikipedia.org/wiki/Ch%C3%A9rubin" target="_blank" rel="noopener noreferrer">Ch&#233;rubin</a>, Act 3)</i></p>

               </div>
               


                <br></br>
               <div className="blog-page-my-words">
                    <h3>Table of Contents</h3>
                </div>
               <div className="blog-page-my-words">
                   <ul>
                        <li>Introduction</li>
                        <li>Zines
                            <ul>
                                <li>Let's make a zine</li>
                                <li>The Zine</li>
                                <li>What I know</li>
                                <li>What I don't know</li>
                                <li>What I think</li>
                            </ul>

                        </li>
                        <li>Winter Reading List</li>
                        <li>Winter Film Festival</li>
                       
                    </ul>
                </div>
                <br></br>
                <div className="blog-page-my-words">
                    <h3>Introduction</h3>
                </div>

                <div className="blog-page-my-words">
                    <p>Labelling content as Winter 2024 will always be confusing to me because the season starts in year n and ends in n+1. I will continue to be bothered by this for the remainder of this blog.</p>
                    <p>This page will track the progress of a few rabbit holes I've been meaning to entertain.</p>
                </div>

                <div className="blog-page-my-words">
                    <h3>Zines</h3>
                </div>

                <div className="blog-page-my-words">
                    <p><marquee scrollamount="8" scrolldelay="90" truespeed><i>manufacturing dreams manufacturing dreams manufacturing dreams manufacturing dreams manufacturing dreams</i></marquee>
                    </p>
                    <h4>Step 0: Let's make a zine</h4>
                    <p>This time, with feeling. Let's make a zine! </p>
                    <p>To own a copy, contact me. Free Delivery.</p>

                    <h4>Step 1: The Zine</h4>
                    <p>If you don't know what a <a href="https://en.wikipedia.org/wiki/Zine" target="_blank" rel="noopener noreferrer">zine</a> is, look it up!. It takes after its older brother "magazines."
                        You will notice that zines have a rich history, tracing back to the 1920s Harlem Renaissance and unfortunately landing into my care in the 2020s. I've had this recurring itch to make one and will track the progress of my first edition release. 
                    </p>

                    <h4>Step 2: What I know</h4>
                    <p>I describe what I know about my approach.
                    </p>
                    <p>First, I need a document editor to export content as a pdf, which will then get printed on paper. I arbitrarily swung between InDesign and Canva. Canva was nice for rough drafts, but I'm going to use InDesign for the real deal. InDesign is important to me because it has richer guidelines for printer interpretations such as bleeding and paging, which becomes especially powerful for double-sided content.</p>
                    <p>Next, I wish to use heavier paper. This means having access to a printer, which accepts this paper. I recently found access to one. That was easy.</p>
                    <p>For binding, I can use a stapler; simple or can I go the extra mile for a long reach stapler. There are exists tools to fold the paper and to cut it. Folding will be challenging with heavier paper. Cutting is a problem because after folding, the pages won't necessarily be the same length. This makes choices like centered content troublesome. The internet doesn't have nice advice on navigating this problem, but some trial & error on my part is expected.</p>

                    <h4>Step 3: What I don't know</h4>
                    <p>I'm not famliar with InDesign at all. I'm not familiar with heavier paper. I'm not familiar with folding it. I'm not familiar with many things.</p>
                    <p>Most importantly, what I need to figure out is InDesign. This is because InDesign contains a feature to assist writers with pagination. Any other editor would require a lot of manual effort, which I hope to avoid. Also, adding a new tool to my creative armamentarium is always exciting.
                    </p>

                    <h4>Step 4: What I think</h4>

                    <p>This Zine will feature words and images. The words will come from many sources, but primarily writers and films. The images will come from films and art pieces whether they come from inspiring artists, or my own work.</p>

                    <p>Still thinking about the title and the flagship piece. I hope I nail it. Without it, this work is nothing.</p>

                    <h4>Step 5: Fonts</h4>

                    <p>Picking fonts is always a daunting process.</p>
                </div>
                
                <div className="blog-page-my-words">
                    <h3>Winter Reading List</h3>
                </div>

                <div className="blog-page-my-words">
                    <ul>
                        <li>Mickey7 (Edward 2022)</li>
                        <li>The Design of Everyday Things (Norman 2013)</li>
                    </ul>
                </div>
                <br></br>
                <div className="blog-page-my-words">
                    <h3>Winter Film Festival</h3>
                </div>

                <div className="blog-page-my-words">
                    <ul>
                        <li>La la land (Chazelle 2016)</li>
                        <li>Dream Scenario (Borgli 2023)</li>
                        <li>Interstellar (Nolan 2014)</li>
                        <li>Manchester by the Sea (Lonergan 2016)</li>
                    </ul>
                <p>I'm on <a href="https://letterboxd.com/atran14/" target="_blank" rel="noopener noreferrer">letterboxd</a>!</p>

                </div>


                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
                <Footer />
            </div>
        </>
    )
}