import React from 'react'
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';


export default function BlogList() {
  return (
    <div>
      <h1>The Jungle</h1>
            <h2 className="latest-posts">Latest Posts</h2>
            <ul className='latest-posts-blog-links'>

                {/* <li>
                    <Link className="blog-paradise" to="/Blog/Paradise">A Farewell to Pensacola / First Fifteen </Link>
                    <time className="timestamp"><p>07 Jun 2023</p></time>
                </li> */}

                {/* <li>
                    <Link to="/Blog/Hallucination"><p>Hallucination</p></Link>
                    <time className="timestamp"><p>Spring 2024</p></time>
                </li> */}
                <li>
                    <Link to="/Blog/The-Sunshine"><p>The Sunshine</p></Link>
                    <time className="timestamp"><p>Winter 2024</p></time>

                </li>

                <li>
                    <Link to="/Blog/Do-as-I-Say-Not-as-I-Do"><p>Do as I Say Not as I Do</p></Link>
                    <time className="timestamp"><p>Autumn 2024</p></time>

                </li>
                
                <li>
                    <Link to="/Blog/DreamMachine"><p>Dream Machine</p></Link>
                    <time className="timestamp"><p>Spring 2024</p></time>
                </li>

                {/* <li>
                    <Link to="/Blog/ToWrite"><p>Writing 2023</p></Link>
                    <time className="timestamp"><p>04 Jan 2024</p></time>
                </li> */}
                  {/* <li>
                    <Link to="/Blog/Twenties"><p>The Roaring '20s</p></Link>
                    <time className="timestamp"><p>08 Oct 2023</p></time>
                </li> */}
                {/* <li>
                    <Link className="blog-paradise" to="/Blog/miFamilia">The Animals</Link>
                    <time className="timestamp"><p>08 Sep 2023</p></time>
                </li> */}

            
              
             

                <li>
                    <Link className="blog-wambly" to="/Blog/Watches">Watches</Link>
                </li>

                {/* <li>
                    <Link className="blog-wambly" to="/Blog/Words">Book Club</Link>
                </li> */}
        
                <li>
                    <Link className="blog-wambly" to="/Blog/Travels">Travels</Link>
                </li>
                
                {/* <li>
                    <Link className="blog-wambly" to="/Blog/Notes">Notes</Link>
                    <time className="timestamp"><p>- Present</p></time>
                </li>
               */}
            </ul>
    </div>
  )
}
